<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Winner List
						<div class="card-header-actions">
							<a
								href="https://coreui.io/vue/docs/components/card-components"
								class="card-header-action"
								rel="noreferrer noopener"
								target="_blank"
							>
								<small class="text-muted">docs</small>
							</a>
						</div>
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<CInput
									label="Country"
									placeholder="Enter Country"
									v-model="moreParams.country"
								/>
							</CCol>
							<CCol md="2">
								<CInput
									label="Year"
									type="number"
									placeholder="Enter Year"
									v-model="moreParams.year"
								/>
							</CCol>
							<CCol md="2">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top:28px;"
									>
										<CIcon name="cil-reload" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height:900px;">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:detail-row-component="detailRow"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
								track-by="id"
							>
								<template slot="slot-country" slot-scope="props">
									{{ props.rowData.country.name }}
								</template>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="showDetail(props.rowData.id)"
										color="secondary"
										size="sm"
										class="py-0"
									>
										<CIcon name="cil-chevron-double-down" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<EllipsisLoader :color="'#54f1d2'" />
							</div>
						</div>

						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import Vuetable from "vuetable-3/src/components/Vuetable";
import { basuri, debounce } from "@/plugins/utils";
import WinnerDetail from "./WinnerDetail";
import { EllipsisLoader } from "vue-spinners-css";

export default {
	components: {
		Vuetable,
		WinnerDetail,
		EllipsisLoader,
	},
	data() {
		return {
			uri: basuri + "winners2/all",
			detailRow: WinnerDetail,
			fields: [
				{
					name: "athlete",
					title: "Athlete",
					sortField: "athlete",
				},
				{
					name: "slot-country",
					title: "Country",
					sortField: "country.name",
				},
				{
					name: "year",
					title: "Year",
					sortField: "year",
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
			moreParams: { filter: null, country: null, year: null },
		};
	},
	mounted() {
		//this.$refs.vuetable.refresh()
	},
	watch: {
		"moreParams.filter": debounce(function(val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
		"moreParams.country": debounce(function(val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
		"moreParams.year": debounce(function(val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
	},
	methods: {
		showDetail(id) {
			this.$refs.vuetable.toggleDetailRow(id);
		},
		reset() {
			this.moreParams = { filter: null, country: null, year: null };
			this.$refs.vuetable.refresh();
			//console.log("relod");
		},
	},
};
</script>
