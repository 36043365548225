<template>
	<div>
		<CRow>
			<pre>
        {{ JSON.stringify(rowData, null, 6) }}
      </pre>
		</CRow>
	</div>
</template>

<script>
export default {
	props: {
		rowData: {
			type: Object,
			required: true,
		},
		rowIndex: {
			type: Number,
		},
	},
};
</script>
